import { useScrollLock } from '@patrianna/shared-hooks'
import { SeonSessionIdProvider } from '@patrianna-payments/analytics/seon/context/SeonSessionIdContext'

import dialogs from 'dialogs/index'
import useOpenDialogByQuery from 'hooks/useOpenDialogByQuery'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { closeLatestDialog } from 'store/modules/dialog/actions'
import {
  getDialogLightBackdropModeSelector,
  getDialogStackSelector,
  getDialogVisibilitySelector,
} from 'store/modules/dialog/selectors'

import RootDialogComponent from './components/RootDialog'

type Props = {
  disablePortal?: boolean
  toggleFullScreen?: Function
}

export default function RootDialog(props: Props) {
  const dispatch = useAppDispatch()
  const dialogStack = useAppSelector(getDialogStackSelector)
  const visible = useAppSelector(getDialogVisibilitySelector)
  const lightBackdropMode = useAppSelector(getDialogLightBackdropModeSelector)

  const dialogComponents = dialogStack.map((dialog) => {
    return {
      component: dialogs[dialog.modalName as keyof typeof dialogs],
      dialogProps: dialog.dialogProps,
      id: dialog.id,
      options: dialog.options,
    }
  })

  useScrollLock(visible)

  useOpenDialogByQuery()

  const closeLastDialog = () => {
    dispatch(closeLatestDialog())
  }

  return (
    <SeonSessionIdProvider>
      <RootDialogComponent
        dialogComponents={dialogComponents}
        open={visible}
        closeLatestDialog={closeLastDialog}
        disablePortal={props.disablePortal}
        toggleFullScreen={props.toggleFullScreen}
        lightBackdropMode={lightBackdropMode}
      />
    </SeonSessionIdProvider>
  )
}

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import { legalRoutes } from 'config/legalRoutes'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { openDialog, removeDialogByName } from 'store/modules/dialog/actions'
import { getDialogVisibilityByNameSelector } from 'store/modules/dialog/selectors'
import { SOCIAL_REGISTRATION, GOOGLE_REG_VALUES, FB_REG_VALUES } from 'utils/sessionStorage'

const AUTH_CONFIRM_DIALOG = 'AUTH_CONFIRM_DIALOG'

export default function useSocialRegistration() {
  const pathname = usePathname()
  const dispatch = useAppDispatch()

  const isConfirmDialogVisible = useAppSelector((state) =>
    getDialogVisibilityByNameSelector(state, AUTH_CONFIRM_DIALOG)
  )
  const isLegalPage = legalRoutes.includes(pathname)

  useEffect(() => {
    if (isLegalPage && isConfirmDialogVisible) {
      dispatch(removeDialogByName({ modalName: AUTH_CONFIRM_DIALOG }))
    }
  }, [isLegalPage, isConfirmDialogVisible])

  useEffect(() => {
    const savedValues = sessionStorage.getItem(SOCIAL_REGISTRATION)
    const socialRegistrationData = savedValues ? JSON.parse(savedValues) : null

    const isGoogleRegistrationNotFinished = Boolean(sessionStorage.getItem(GOOGLE_REG_VALUES))
    const isFBRegistrationNotFinished = Boolean(sessionStorage.getItem(FB_REG_VALUES))

    if (!isLegalPage && socialRegistrationData && (isGoogleRegistrationNotFinished || isFBRegistrationNotFinished)) {
      dispatch(openDialog({ modalName: AUTH_CONFIRM_DIALOG, dialogProps: socialRegistrationData }))
    }
  }, [isLegalPage])
}

import { throttle } from '@patrianna/shared-utils'
import { useCallback, useEffect, useRef } from 'react'

import type { UseHorizontalScrollRefsContract } from '../HorizontalScroll.types'
import { scrollTo } from '../utils/scrollTo'

export const useHorizontalScrollRefs: UseHorizontalScrollRefsContract = ({ onScroll, customScroll, scrollPage }) => {
  const wrapperRef = useRef<HTMLDivElement>()
  const scrollRef = useRef<HTMLDivElement>()
  const scrollPageRef = useRef<number>()

  // check if 'prev' or 'next' navigation arrows should be hidden.
  // For example, initially we hide 'prev' arrow because content can't be scrolled to left.
  // when user scroll to the end of list we hide 'next' arrow
  const checkArrowsVisibility = useCallback(() => {
    const container = scrollRef.current
    const wrapper = wrapperRef.current

    if (!container || !wrapper) {
      return undefined
    }

    // check 'prev' arrow
    const shouldHidePrevArrow = container.scrollLeft === 0

    if (shouldHidePrevArrow) {
      wrapper.classList.add('hidePrevlArrow')
    } else {
      wrapper.classList.remove('hidePrevlArrow')
    }

    // check 'next' arrow
    const scrolledContainerWidth = Math.ceil(container.scrollLeft + container.offsetWidth)
    const shouldHideNextArrow = container.scrollWidth <= scrolledContainerWidth

    if (shouldHideNextArrow) {
      wrapper.classList.add('hideNextlArrow')
    } else {
      wrapper.classList.remove('hideNextlArrow')
    }
  }, [])

  const throttledCheckArrowsVisibility = throttle(checkArrowsVisibility, 500)

  const getScrollDistance = useCallback((): number => {
    if (!scrollRef.current) {
      return 0
    }

    const gridGap = parseInt(getComputedStyle(scrollRef.current).gap, 10) || 12
    const childrenWidth = scrollRef.current.children[0]?.clientWidth || 0

    return childrenWidth + gridGap
  }, [])

  const handleScroll = useCallback(() => {
    const container = scrollRef.current
    if (!container) {
      return
    }
    throttledCheckArrowsVisibility()
    onScroll?.(container)
  }, [onScroll, throttledCheckArrowsVisibility])

  const prevScroll = useCallback(() => {
    const distance = getScrollDistance()

    if (scrollRef.current) {
      scrollTo(scrollRef.current, 'left', distance)
    }
    checkArrowsVisibility()
  }, [checkArrowsVisibility, getScrollDistance])

  const nextScroll = useCallback(() => {
    const distance = getScrollDistance()

    if (scrollRef.current) {
      scrollTo(scrollRef.current, 'right', distance)
    }
    checkArrowsVisibility()
  }, [checkArrowsVisibility, getScrollDistance])

  useEffect(() => {
    if (scrollRef.current) {
      customScroll?.(scrollRef.current)
    }
    // FYI: Do not add dependencies to abstract components; this should be decided at the top level.
  })

  useEffect(() => {
    if (scrollPage > scrollPageRef.current) {
      nextScroll()
    } else if (scrollPage < scrollPageRef.current) {
      prevScroll()
    }
    scrollPageRef.current = scrollPage
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [scrollPage])

  return {
    wrapperRef,
    scrollRef,
    prevScroll,
    nextScroll,
    handleScroll,
    checkArrowsVisibility,
  }
}

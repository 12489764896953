import { useIsIos, useIsFbBrowser } from '@patrianna/shared-hooks'
import type { SocialAuthTypes } from '@patrianna/shared-patrianna-types/store/AuthModule'
import { getAuthFlowLoadingSelector } from '@patrianna/shared-store/auth'
import { usePathname, useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { useState, useEffect } from 'react'

import { useAuth } from 'app/context/auth'
import { useRouter } from 'app/context/navigation'
import { trackEvent } from 'config/analytic'
import { GOOGLE_APP_ID } from 'config/apps'
import { googleOneTapAuth } from 'services/google/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openSnackbar } from 'store/modules/snackbar/actions'
import ROUTES from 'temp/routes.json'

function GoogleAuthOneTap() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const { isLoggedIn } = useAuth()
  const isAuthLoading = useAppSelector(getAuthFlowLoadingSelector)
  const isIos = useIsIos()
  const isFbBrowser = useIsFbBrowser()
  const { push } = useRouter()
  const query = useSearchParams()
  const pathname = usePathname()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Close One Tap popup if another auth flow is in progress
    if (isScriptLoaded && isAuthLoading) {
      window.google.accounts.id.cancel()
    }
  }, [isScriptLoaded, isAuthLoading])

  const credentialResponseHandler = (token: string) => {
    trackEvent('googlequickform_sign')

    if (isIos && isFbBrowser) {
      dispatch(
        openSnackbar({
          message: `Please try to use a different sign-in method or visit ${window.location.origin} from your web browser (Safari, Chrome etc)`,
        })
      )
    } else {
      const authType: SocialAuthTypes = pathname === ROUTES.REGISTER ? 'REGISTRATION' : 'LOGIN'
      dispatch(googleOneTapAuth(authType, token, null, push, query, pathname))
    }
  }

  const shouldGoogleOneTapBeHidden =
    !process.env.GOOGLE_AUTH_ONE_TAP_ENABLED ||
    !GOOGLE_APP_ID ||
    isLoggedIn ||
    (isIos && isFbBrowser && pathname === ROUTES.REGISTER)

  if (shouldGoogleOneTapBeHidden) {
    return null
  }

  return (
    <Script
      src={process.env.GOOGLE_GSI_CLIENT_SCRIPT_URL}
      onLoad={() => {
        setIsScriptLoaded(true)
        window.google.accounts.id.initialize({
          client_id: GOOGLE_APP_ID,
          callback: (response) => credentialResponseHandler(response.credential),
        })
        window.google.accounts.id.prompt()
      }}
      onError={(err) => {
        console.log('google one tap auth failed: ', err)
      }}
    />
  )
}

export default GoogleAuthOneTap

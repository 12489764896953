import { useTranslation } from '@patrianna/core-components'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'

import { useRouter } from 'app/context/navigation'
import useIsLandscape from 'hooks/useIsLandscape'
import useOtpConfirmRequired from 'hooks/useOtpConfirmRequired'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getFreeSpinsScratchCardsSelector, getFreeSpinsSlotGamesSelector } from 'store/modules/freeSpins/selectors'
import { startExternalGameFlow } from 'store/modules/slotGameFlow/actions'
import type { FreeSpinWithIdType } from 'store/types/FreeSpinsModule'

import Carousel from './components/Carousel'
import FreeSpinBarItem from './components/FreeSpinBarItem'
import classes from './FreeSpinsBar.module.scss'

type Props = {
  className?: string
  gameProduct?: GameProduct
}

const FreeSpinsBar = ({ className, gameProduct }: Props) => {
  const { push } = useRouter()
  const t = useTranslation()
  const freeSpinScratchCards = useAppSelector((state) => getFreeSpinsScratchCardsSelector(state, gameProduct?.code))
  const freeSpinSlotGames = useAppSelector((state) => getFreeSpinsSlotGamesSelector(state, gameProduct?.code))
  const dispatch = useAppDispatch()
  const isLandspace = useIsLandscape()
  const otpRequired = useOtpConfirmRequired()

  const openGame = (currentFreeSpin: FreeSpinWithIdType) => {
    dispatch(startExternalGameFlow(currentFreeSpin.products[0], currentFreeSpin.currency, push, { otpRequired }))
  }

  return (
    <div
      className={cx(classes.root, className, {
        [classes.gap]: !!freeSpinSlotGames.length && !!freeSpinScratchCards.length,
      })}
    >
      <div className={classes.row}>
        {!!freeSpinSlotGames.length && (
          <Carousel transitionTime={isLandspace ? 1400 : 1000}>
            {freeSpinSlotGames.map((freeSpin) => (
              <FreeSpinBarItem
                onClickItem={openGame}
                key={freeSpin.id}
                title={t('dialogFreeSpinsDialog.slot_game_reward')}
                freeSpin={freeSpin}
              />
            ))}
          </Carousel>
        )}
      </div>
      <div className={classes.row}>
        {!!freeSpinScratchCards.length && (
          <Carousel>
            {freeSpinScratchCards.map((freeSpin) => (
              <FreeSpinBarItem
                onClickItem={openGame}
                key={freeSpin.id}
                title={t('dialogFreeSpinsDialog.scratchcard_reward')}
                freeSpin={freeSpin}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  )
}

export default FreeSpinsBar

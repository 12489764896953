import type { ProvincesAndStatesResponse } from '@patrianna/shared-utils/countryStates/types'

import gatewayInstance from 'services/gateway'

export async function getSignUpStates() {
  try {
    const result = await gatewayInstance.emit<{ countriesAndStates: ProvincesAndStatesResponse }>({
      type: 'GetSignupCountriesAndStatesRequest',
    })

    return result?.countriesAndStates
  } catch (error) {
    console.error(`Error while fetching getSignUpState, (${getSignUpStates.name})`, error)

    return []
  }
}

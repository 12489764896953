import { IconButton } from '@patrianna/core-components'
import { useTranslation } from '@patrianna/core-components'
import { throttle } from '@patrianna/shared-utils'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

import classes from './ScrollToTop.module.scss'

type Props = {
  handleButtonVisibility?: (showButton: boolean) => void
}

export default function ScrollToTop({ handleButtonVisibility }: Props) {
  const t = useTranslation()
  const [showButton, setShowButton] = useState(false)
  const lastScrollPos = useRef(0)

  const handleScroll = () => {
    if (typeof window === 'undefined') {
      return
    }

    const currentScrollPos = window.pageYOffset

    if (currentScrollPos < lastScrollPos.current && currentScrollPos) {
      setShowButton(true)
      handleButtonVisibility?.(true)
    } else {
      setShowButton(false)
      handleButtonVisibility?.(false)
    }

    lastScrollPos.current = currentScrollPos
  }

  const throttleHandleScroll = throttle(handleScroll, 500)

  useEffect(() => {
    window.addEventListener('scroll', throttleHandleScroll)

    return () => {
      window.removeEventListener('scroll', throttleHandleScroll)
    }
  }, [throttleHandleScroll])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <IconButton
      data-component='scroll-to-top'
      className={cx(classes.root, {
        [classes.showButton]: showButton,
      })}
      onClick={scrollToTop}
    >
      <span className={classes.title}>{t('common.scroll_to_top')}</span>
      <img
        className={cx(classes.upIcon)}
        src={`${process.env.IMG_ORIGIN}/menu/accordion_down.svg`}
        width={20}
        height={20}
        alt='accordion arrow up'
        aria-hidden='true'
      />
    </IconButton>
  )
}

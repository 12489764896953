import { useTranslation } from '@patrianna/core-components'
import { transformProvincesAndStates } from '@patrianna/shared-utils/countryStates'
import type {
  CanadaProvince,
  SignUpProvinceAndState,
  USStates,
  AustraliaState,
} from '@patrianna/shared-utils/countryStates/types'
import { useEffect, useState } from 'react'

import { getSignUpStates } from 'utils/getSignupStates'

let cachedStates: SignUpProvinceAndState = null

export const useAuthStates = () => {
  const t = useTranslation()
  const initialState = {
    US: {
      allowedStates: [{ value: '' as USStates, name: t('authPages.select_state') }],
      blockedStates: [],
    },
    CA: {
      allowedStates: [{ value: '' as CanadaProvince, name: t('authPages.select_province') }],
      blockedStates: [],
    },
    AU: {
      allowedStates: [{ value: '' as AustraliaState, name: t('authPages.select_state') }],
      blockedStates: [],
    },
  } as SignUpProvinceAndState

  const [provincesAndStates, setProvincesAndStates] = useState<SignUpProvinceAndState>(initialState)

  const isStatesAvailableByCountry = (countryKey: string) => {
    return Object.prototype.hasOwnProperty.call(provincesAndStates, countryKey)
  }

  useEffect(() => {
    const fetchStates = async () => {
      if (!cachedStates) {
        const states = await getSignUpStates()

        cachedStates = transformProvincesAndStates({ provincesAndStates: states, t })
      }
      setProvincesAndStates(cachedStates)
    }

    fetchStates()
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  return { provincesAndStates, isStatesAvailableByCountry }
}

import { SentryChannel, SentryEvent } from '@patrianna-payments/mf-channels'
import type {
  SentryCaptureExceptionType,
  SentryCaptureMessageType,
} from '@patrianna-payments/shared-store/sentry.types'
import * as Sentry from '@sentry/nextjs'

import { useSubscribeAction } from 'services/pubsub_con'
import { useAppDispatch } from 'store/hooks'
import { handleSentryCaptureException, handleSentryCaptureMessage } from 'store/middlewares/handlers/sentry'

export const useSentrySubscriptions = () => {
  const dispatch = useAppDispatch()
  useSubscribeAction<{ message: string; captureContext?: string }>(
    SentryChannel.Actions,
    SentryEvent.CaptureMessage,
    (payload) => {
      Sentry.captureMessage.apply(null, Object.values(payload))
    }
  )
  useSubscribeAction<SentryCaptureMessageType>(
    SentryChannel.Actions,
    SentryEvent.HandleSentryCaptureMessage,
    (payload) => {
      dispatch(handleSentryCaptureMessage(payload))
    }
  )

  useSubscribeAction<{ exception: string; captureContext?: string }>(
    SentryChannel.Actions,
    SentryEvent.CaptureException,
    (payload) => {
      Sentry.captureException.apply(null, Object.values(payload))
    }
  )
  useSubscribeAction<SentryCaptureExceptionType>(
    SentryChannel.Actions,
    SentryEvent.HandleSentryCaptureException,
    (payload) => {
      dispatch(handleSentryCaptureException(payload))
    }
  )
}

import gatewayInstance from 'services/gateway'

import { arrayFilterNullable } from './array/filterNullable'
import type { CountryCode } from './countryCodes'

export async function getSignUpCountries() {
  try {
    const result = await gatewayInstance.emit<{ countries: CountryCode[] }>({
      type: 'GetSignupCountriesRequest',
    })

    return arrayFilterNullable(result?.countries)
  } catch (error) {
    console.error(`Error while fetching getSignUpCountries, (${getSignUpCountries.name})`, error)

    return []
  }
}

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import { useSessionDialogReopenOnRouteChangeFlags } from 'containers/SessionDialogsProvider/hooks/useSessionDialogReopenOnRouteChangeFlags'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { closeLatestDialog, openDialog } from 'store/modules/dialog/actions'
import { removeFromHistorySessionDialogs, setHistorySessionDialogs } from 'store/modules/sessionDialogs/actions'
import type { DialogNames } from 'store/types'
import dialogConfig from 'temp/dialogsConfig.json'

import { actionsMap } from './actionsMap'
import type { SessionDialog } from './types'
import { useSessionDialogFlags } from './useSessionDialogFlags'
import { checkRouteList, getNextSessionDialog } from './utils'

const dialogs = dialogConfig as unknown as SessionDialog[]

type Props = {
  visibleSessionDialog: DialogNames
  historySessionDialogs: DialogNames[]
}

export default function OpenSessionDialogs({ visibleSessionDialog, historySessionDialogs }: Props): null {
  const dispatch = useAppDispatch()
  const pathname = usePathname()
  const sweepstakeEnabled = useAppSelector(sweepstakeEnabledSelector)
  const currency = sweepstakeEnabled ? 'SC' : 'GC'
  const flags = useSessionDialogFlags()
  const ignoreReopenOnRouteChangeFlags = useSessionDialogReopenOnRouteChangeFlags()

  // If current open dialog is session dialog and open on ignore route
  // when remove it from store and close
  const closeDialogInIgnoreRoute = () => {
    if (visibleSessionDialog) {
      const sessionDialog = dialogs.find((dialog) => dialog.modalName === visibleSessionDialog)
      const isIgnoreRoute = checkRouteList(sessionDialog?.ignoreRoutes, pathname)

      if (isIgnoreRoute) {
        dispatch(removeFromHistorySessionDialogs({ modalName: visibleSessionDialog }))
        dispatch(closeLatestDialog())
      }
    }
  }

  // this function validate `reopenOnRouteChange` prop - this field allows to reopen modal again when user closed modal without accepting it and moved to the next route.
  // https://patrianna.atlassian.net/browse/FE-38
  const verifyReopenOnRouteChange = () => {
    historySessionDialogs.forEach((modalName) => {
      const removeDialog = dialogs.find(
        (dialog: SessionDialog) => dialog.modalName === modalName && dialog.reopenOnRouteChange
      )

      if (
        removeDialog &&
        flags[removeDialog.flag] &&
        !ignoreReopenOnRouteChangeFlags[removeDialog.ignoreReopenOnRouteChangeFlag]
      ) {
        dispatch(removeFromHistorySessionDialogs({ modalName }))
      }
    })
  }

  const handleOpenDialogs = () => {
    // get next session dialog by platform and currency and flag
    const sessionDialog = getNextSessionDialog(
      dialogs,
      {
        currency,
        platform: 'WEB',
        flags,
      },
      historySessionDialogs,
      pathname
    )

    if (!sessionDialog) {
      return
    }

    const { modalName, action } = sessionDialog

    // Open next dialog and added to history session dialogs
    if (!visibleSessionDialog) {
      dispatch(action ? actionsMap[action as keyof typeof actionsMap]() : openDialog({ modalName }))
      dispatch(setHistorySessionDialogs({ modalName }))
    }
  }

  useEffect(() => {
    closeDialogInIgnoreRoute()
    handleOpenDialogs()
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [visibleSessionDialog, pathname, historySessionDialogs])

  // validate `reopenOnRouteChange`
  useEffect(() => {
    verifyReopenOnRouteChange()
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [pathname])

  return null
}

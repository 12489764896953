'use client'

import { forwardRef, useImperativeHandle, type Ref } from 'react'

import HorizontalScrollComponent from './components'
import { useHorizontalScrollArrows } from './hooks/useHorizontalScrollArrows'
import { useHorizontalScrollRefs } from './hooks/useHorizontalScrollRefs'
import type { HorizontalScrollProps } from './HorizontalScroll.types'

function HorizontalScroll(
  { className = '', classNameContainer = '', children, BackDropNode, ...props }: HorizontalScrollProps,
  ref: Ref<HTMLElement>
) {
  const { wrapperRef, scrollRef, prevScroll, nextScroll, handleScroll, checkArrowsVisibility } =
    useHorizontalScrollRefs({
      customScroll: props.customScroll,
      onScroll: props.onScroll,
      scrollPage: props.scrollPage,
    })

  const { isArrowsShown } = useHorizontalScrollArrows({
    showArrows: props.showArrows,
    wrapperRef,
    checkArrowsVisibility,
  })

  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  useImperativeHandle(ref, () => scrollRef.current, [])

  return (
    <HorizontalScrollComponent
      wrapperRef={wrapperRef}
      scrollRef={scrollRef}
      onScroll={handleScroll}
      prevScroll={prevScroll}
      nextScroll={nextScroll}
      className={className}
      classNameContainer={classNameContainer}
      showArrows={isArrowsShown}
      data-test={props?.['data-test']}
      BackDropNode={BackDropNode}
    >
      {typeof children === 'function'
        ? children({ wrapperRef, scrollRef, prevScroll, nextScroll, handleScroll, checkArrowsVisibility })
        : children}
    </HorizontalScrollComponent>
  )
}

export default forwardRef(HorizontalScroll)

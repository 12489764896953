import { useAppSelector } from 'store/hooks'
import { isIgnoreReopenOnRouteChangeDailyBonusSelector } from 'store/modules/bonusesFlow/selectors'

import type { SessionDialogReopenOnRouteChangeFlags } from '../types'

export const useSessionDialogReopenOnRouteChangeFlags = (): SessionDialogReopenOnRouteChangeFlags => {
  const isIgnoreReopenOnRouteChangeDailyBonus = useAppSelector(isIgnoreReopenOnRouteChangeDailyBonusSelector)

  return {
    isIgnoreReopenOnRouteChangeDailyBonus,
  }
}

import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { useChatBot } from '@patrianna-payments/chatbot'
import type { ZendeskChatBotProps } from '@patrianna-payments/zendesk'
import { ZendeskSaveChatEventEvent } from '@patrianna-payments/zendesk'
import ZendeskChatBot from '@patrianna-payments/zendesk'
import { saveZendeskChatEventRequest } from '@patrianna-payments/zendesk'
import { ZendeskSaveChatEventFlow } from '@patrianna-payments/zendesk'
import { closeZendeskChatByCommand } from '@patrianna-payments/zendesk'
import { useEffect } from 'react'

import useDomEvent from 'hooks/useDomEvent'
import useShowChats, { APP_ID, CHAT_BOT_ID, SNIPPET_KEY } from 'hooks/useShowChats'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import {
  getDialogVisibilityByNameSelector,
  getPaymentDialogsOpenedSelector,
  getPaymentErrorExistSelector,
} from 'store/modules/dialog/selectors'
import {
  getLiveChatDepartmentsSelector,
  getLiveChatSettingsSelector,
  getUserSelector,
} from 'store/modules/user/selectors'
import { getDataFromSessionStorage, IS_OPENED_PAYMENT_MODALS } from 'utils/sessionStorage'

export default function Chats() {
  const user = useAppSelector(getUserSelector)
  const departments = useAppSelector(getLiveChatDepartmentsSelector)
  const liveChatSettings = useAppSelector(getLiveChatSettingsSelector)
  const isPaymentDialogsOpened = useAppSelector(getPaymentDialogsOpenedSelector)
  const isPaymentDialogOpened = useAppSelector((state) => getDialogVisibilityByNameSelector(state, 'PAYMENT_DIALOG'))
  const isPaymentErrorExist = useAppSelector(getPaymentErrorExistSelector)
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()

  const { isShowLiveChat, isShowLiveChatFromMenu, isShowChatBot, isShowChatBotCom } = useShowChats()

  useChatBot(CHAT_BOT_ID, !isShowLiveChat && isShowChatBotCom)

  const bottomBarStateHandler = (e: CustomEvent) => {
    const botContainer = document.getElementById('chatbot-chat')

    if (botContainer?.style) {
      botContainer.style.transform = `translateY(${e.detail.isBottomOpened ? '-135px' : '0'})`
    }
  }

  useDomEvent('BOTTOM_BAR_STATE', bottomBarStateHandler)

  const onChatOpen = () => {
    const isPaymentModals = !!+getDataFromSessionStorage(IS_OPENED_PAYMENT_MODALS) //type of data in SS - string (0,1)

    if (isPaymentModals) {
      dispatch(
        saveZendeskChatEventRequest(ZendeskSaveChatEventEvent.CHAT_OPENED, ZendeskSaveChatEventFlow.PURCHASE_FLOW)
      )
    }
  }

  useEffect(() => {
    if (!isPaymentDialogsOpened && !isPaymentErrorExist && isMobile) {
      closeZendeskChatByCommand()
    }
  }, [isPaymentDialogsOpened, isPaymentErrorExist, isMobile])

  useEffect(() => {
    if (isShowLiveChat && isPaymentDialogOpened) {
      dispatch(
        saveZendeskChatEventRequest(ZendeskSaveChatEventEvent.CHAT_SHOWN, ZendeskSaveChatEventFlow.PURCHASE_FLOW)
      )
    }
  }, [isPaymentDialogOpened, isShowLiveChat])

  if (!isShowLiveChatFromMenu && !isShowLiveChat && !isShowChatBot && !isShowChatBotCom) {
    return null
  }

  const chatBotProps: ZendeskChatBotProps = {
    appId: APP_ID,
    snippetKey: SNIPPET_KEY,
    info: user,
  }

  const additionalStyles =
    (isPaymentDialogsOpened || isPaymentErrorExist) && isShowLiveChat
      ? `
      #launcher {
        display: block !important;
      }
      @media (max-width: 932px) {
        #launcher {
          bottom: 0px !important;
          margin: 20px !important;
        }
        #webWidget {
          width: 88% !important;
          height: 88% !important;
          top: auto !important;
          left: auto !important;
          right: 0px !important;
          bottom: 0px !important;
        }
      }
      #webWidget {
        @media all and (display-mode: standalone) and (orientation: portrait) {
          top: env(safe-area-inset-top) !important;
          height: calc(100% - env(safe-area-inset-top)) !important;
        }
      }
    `
      : `
      #launcher {
        display: none !important;
      }
      @media screen and (max-width: 932px) {
        #webWidget {
          width: 100% !important;
          height: 100% !important;
          top: 0px !important;
          left: 0px !important;
          right: auto !important;
          bottom: auto !important;
        }
      }
      #webWidget {
        @media all and (display-mode: standalone) and (orientation: portrait) {
          top: env(safe-area-inset-top) !important;
          height: calc(100% - env(safe-area-inset-top)) !important;
        }
      }
    `

  if (isShowLiveChat || isShowLiveChatFromMenu) {
    return (
      <ZendeskChatBot
        {...chatBotProps}
        departments={departments}
        customerTag={liveChatSettings?.tag}
        additionalStyles={additionalStyles}
        onChatOpenCallback={onChatOpen}
      />
    )
  }

  if (isShowChatBotCom) {
    return null
  }

  if (isShowChatBot) {
    return <ZendeskChatBot {...chatBotProps} withBot standaloneChatBot />
  }

  return null
}

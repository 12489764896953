import type { SocialAuthTypes } from '@patrianna/shared-patrianna-types/store/AuthModule'
import { googleAuth as googleAuthAction } from '@patrianna/shared-store/auth/store/googleActions'
import type { useSearchParams } from 'next/navigation'

import { GOOGLE_APP_ID } from 'config/apps'
import type { TypedThunk } from 'store'
import { googleOneTapAuthHandler } from 'store/modules/auth/actions'
import { LS_AUTH_TYPE, setDataToLocalStorage } from 'utils/localStorage'

export const googleAuth =
  (authType: SocialAuthTypes): TypedThunk =>
  (dispatch) => {
    dispatch(setDataToLocalStorage(LS_AUTH_TYPE, authType, false))
    dispatch(googleAuthAction({ authType, gId: GOOGLE_APP_ID }))
  }

export const googleOneTapAuth =
  (
    authType: SocialAuthTypes,
    token: string,
    email: string,
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string
  ): TypedThunk =>
  (dispatch) => {
    dispatch(setDataToLocalStorage(LS_AUTH_TYPE, authType, false))
    dispatch(googleOneTapAuthHandler(token, email, routerPush, routerQuery, routerPath))
  }

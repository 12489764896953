// B2SPIN-5716 AB test 'Round product tiles (Recently Launched swimlane)'
import type { GameProductShort, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import { trackOptimizelyEvent } from '@patrianna-payments/analytics/optimizely/events'

import { useABTest } from 'hooks/useABTest'
import { OptimizedProductTile } from 'pageContainers/Home/components/SlotsRow'
import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'

import RoundProductTile from './RoundProductTile'

export const ROUND_TILES = 'round_tiles'

type Props = {
  game: GameProductShort
  style?: SwimlaneCategoryStyle
  isRound?: boolean
}

const RoundTilesExperiment = ({ game, style }: Props) => {
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const [isABTestRoundTiles] = useABTest(ROUND_TILES)

  const clickHandler = () => {
    if (isScEnabled) {
      //TODO: remove when 'tile_click_swimlane' will be added to payment-packages
      // @ts-ignore
      trackOptimizelyEvent('tile_click_swimlane')
    }
  }

  return (
    <div onClick={clickHandler}>
      {isABTestRoundTiles && isScEnabled ? (
        <RoundProductTile game={game} key={`${game.code}-round`} style={style} />
      ) : (
        <OptimizedProductTile game={game} style={style} />
      )}
    </div>
  )
}

export default RoundTilesExperiment

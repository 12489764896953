import type {
  GameProductShort,
  SwimlaneCategoryStyle,
  SwimlaneCategoryType,
} from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import { memo, useCallback, useEffect, useState } from 'react'

import GameRowHeader from 'components/GameRowHeader'
import ProductTile from 'components/ProductTile'
import HorizontalScroll from 'containers/HorizontalScroll'
import useScrollable from 'hooks/useScrollable'

import RoundTilesExperiment from '../../../../experiments/RoundTilesExperiment'

import classes from './styles.module.scss'

type Props = {
  games: Array<GameProductShort>
  title: string
  url?: string
  count?: number
  itemsPerLineNumber?: number
  iconImg?: string
  className?: string
  isMobileUserAgent: boolean
  lines?: number
  style?: SwimlaneCategoryStyle
  type?: SwimlaneCategoryType
  numberOfPreLoadedItems?: number
  hideHeader?: boolean
  showArrows?: boolean
  promotionUrl?: string
  categoryCode?: string
}

// eslint-disable-next-line
export const OptimizedProductTile = memo(
  ({ game, style }: { game: GameProductShort; style?: SwimlaneCategoryStyle }) => (
    <ProductTile className={classes.slotTile} game={game} key={game.code} swimlaneStyle={style} />
  ),
  (prevProps, nextProps) => prevProps.game.code === nextProps.game.code
)

export default function SlotsRow(props: Props) {
  const { games, isMobileUserAgent, lines = 1, numberOfPreLoadedItems = 10, ...headerProps } = props
  const [scrollPage, setScrollPage] = useState(0)

  const [visibleGames, setVisibleGames] = useState(games.slice(0, numberOfPreLoadedItems))

  const onScroll = useCallback(
    (container: HTMLDivElement) => {
      if (Math.ceil(container.scrollLeft) + container.clientWidth >= container.scrollWidth) {
        const nextItems = games.slice(visibleGames.length, visibleGames.length + lines)
        setVisibleGames((prevItems) => [...prevItems, ...nextItems])
      }
    },
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
    [games, visibleGames.length]
  )

  useEffect(() => {
    setVisibleGames(games.slice(0, numberOfPreLoadedItems))
  }, [games, numberOfPreLoadedItems])

  const scrollNext = () => {
    setScrollPage((prevState) => prevState + 1)
  }

  const scrollPrev = () => {
    setScrollPage((prevState) => prevState - 1)
  }

  const [ref, isScrollable] = useScrollable()

  if (props.games?.length) {
    return (
      <div className={props.className}>
        {!props.hideHeader && (
          <GameRowHeader
            {...headerProps}
            scrollPrev={scrollPrev}
            scrollNext={scrollNext}
            showArrows={!isMobileUserAgent}
            hideNavigation={!isScrollable}
          />
        )}
        <HorizontalScroll
          showArrows={props.showArrows}
          data-test={`${props.title}-games-row`}
          scrollPage={scrollPage}
          classNameContainer={cx(
            classes.tilesGrid,
            classes.tilesContainer,
            `lines-${props.lines}`,
            `style-${props.style}`
          )}
          ref={ref}
          onScroll={onScroll}
        >
          {visibleGames.map((game) => {
            return props.categoryCode === 'new' ? (
              <RoundTilesExperiment game={game} key={`${game.code}-new`} style={props.style} />
            ) : (
              <OptimizedProductTile game={game} key={game.code} style={props.style} />
            )
          })}
        </HorizontalScroll>
      </div>
    )
  }

  return null
}

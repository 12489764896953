'use client'

import { usePageViewTracker } from '@patrianna/shared-hooks'
import { getCookie } from '@patrianna/shared-utils/cookie'
import { getUserOS } from '@patrianna/shared-utils/getUserOS'
import { GraphyteScript } from '@patrianna-payments/analytics'
import { ClarityScript } from '@patrianna-payments/analytics/clarity/script'
import { graphytePageView } from '@patrianna-payments/analytics/graphyte/events'
import { setInitialTrafficSource } from '@patrianna-payments/analytics/initialTrafficSource/script'
import AnalyticsScripts from '@patrianna-payments/analytics/main/core/Scripts'
import Optimizely from '@patrianna-payments/analytics/optimizely/script'
import SaveWebReferrerScript from '@patrianna-payments/analytics/save-web-referrer/script'
import { configureScope } from '@sentry/nextjs'
import { usePathname } from 'next/navigation'
import type { ReactNode } from 'react'
import { useEffect } from 'react'

import { useAuth } from 'app/context/auth'
import { useRouter } from 'app/context/navigation'
import StickyElementsContainer from 'components/StickyElementsContainer'
import { legalRoutes } from 'config/legalRoutes'
import GoogleAuthOneTap from 'containers/GoogleAuthOneTap'
import PWAProvider from 'containers/PWAProvider'
import RemoteEntryPaymentGlobal from 'containers/RemoteEntry/global'
import SaveWebReferrer from 'containers/SaveWebReferrer'
import SessionDialogsProvider from 'containers/SessionDialogsProvider'
import TurnOffSpinnerInButtons from 'containers/TurnOffSpinnerInButtons'
import VisibilityProvider from 'containers/VisibilityProvider'
import RootDialog from 'dialogs/components/RootDialog'
import { useAnalyticSubscriptions } from 'hooks/mf/useAnalyticSubscription'
import { useAuthCountriesMF } from 'hooks/mf/useAuthCountriesMF'
import { useAuthStatesMF } from 'hooks/mf/useAuthStatesMF'
import { useOtpMandatoryAndClosed } from 'hooks/mf/useOtpMandatoryAndClosed'
import { useRouterSubscriptions } from 'hooks/mf/useRouterSubscriptions'
import { useSentrySubscriptions } from 'hooks/mf/useSentryMf'
import useAppInit from 'hooks/useAppInit'
import useRegisterWorkbox from 'hooks/useRegisterWorkbox'
import useRequireKYCIdentityVerification from 'hooks/useRequireKYCIdentityVerification'
import useSeonInit from 'hooks/useSeonInit'
import useSocialRegistration from 'hooks/useSocialRegistration'
import { useAppSelector } from 'store/hooks'
import { getGraphyteEnabledSelector, needToVerifyEmailSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

export function RootLayoutClient({ children }: { children: ReactNode }) {
  const router = useRouter()
  const pathname = usePathname()
  const emailNotVerified = useAppSelector(needToVerifyEmailSelector)
  const { isLoggedIn } = useAuth()
  const skipOptWithClosedAndMandatory = useOtpMandatoryAndClosed()

  const graphyteEnabled = useAppSelector(getGraphyteEnabledSelector)

  const isAnalyticsAvailable = process.env.PROFILE === 'prod' || process.env.PROFILE === 'dev'

  useAppInit()
  useRequireKYCIdentityVerification()
  useRegisterWorkbox()
  useSeonInit()
  useSocialRegistration()
  // MF start
  useRouterSubscriptions()
  useAnalyticSubscriptions()
  useAuthStatesMF()
  useAuthCountriesMF()
  useSentrySubscriptions()
  // MF end

  usePageViewTracker(() => {
    if (graphyteEnabled) {
      graphytePageView()
    }
  }, [graphyteEnabled])

  useEffect(() => {
    setInitialTrafficSource()

    if (emailNotVerified && !legalRoutes.some((route: string) => pathname.includes(route))) {
      router.push(ROUTES.CONFIRM_EMAIL)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [emailNotVerified, pathname])

  useEffect(() => {
    const userId = getCookie(`${process.env.BRAND_NAME.toUpperCase()}AID`, document.cookie)

    if (userId) {
      configureScope((scope) => scope.setUser({ id: userId }))
    }

    document.body.classList.add(getUserOS())
  }, [])

  return (
    <>
      <RemoteEntryPaymentGlobal />
      <GoogleAuthOneTap />
      <SaveWebReferrer />
      <TurnOffSpinnerInButtons />
      {isLoggedIn && <VisibilityProvider />}
      {children}
      <RootDialog />
      <PWAProvider />

      {skipOptWithClosedAndMandatory && !emailNotVerified && isLoggedIn && <SessionDialogsProvider />}

      {isAnalyticsAvailable && (
        <>
          <AnalyticsScripts />
          <GraphyteScript profile={process.env.GRAPHYTE_API_KEY} brandKey={process.env.GRAPHYTE_BRAND_KEY} />
          <ClarityScript profile={process.env.CLARITY} />
          <Optimizely url={process.env.OPTIMIZELY_URL} />
        </>
      )}
      <StickyElementsContainer />
      <SaveWebReferrerScript rootUrl={ROUTES.INDEX} />
    </>
  )
}

import { capitalize } from '@patrianna/core-components/src/helpers/capitalize'
import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/SlotGameFlowModule'
import type { ReactElement } from 'react'
import { cloneElement } from 'react'
import { v4 as uuid } from 'uuid'

export function getFreeSpinText(freeSpin: FreeSpinType, t: (id: string) => string) {
  const rounds = freeSpin?.rounds - freeSpin?.roundsPlayed
  const game = freeSpin?.products[0]
  const entityType = game.type === 'scratch_card' ? 'game' : 'spin'
  const pluralSuffix = rounds > 1 ? 's' : ''
  const entity = capitalize(t(`common.${entityType}${pluralSuffix}`))
  const freeLabelCapitalized = capitalize(t('dialogFreeSpinsDialog.free').toLowerCase())

  return `${rounds} ${freeLabelCapitalized} ${entity} on ${game.title}`
}

export function getInitialTrack(children: ReactElement[]) {
  return children.length === 2
    ? [
        ...children,
        cloneElement(children[0], { key: children[0].key + uuid() }),
        cloneElement(children[1], { key: children[1].key + uuid() }),
      ]
    : children
}

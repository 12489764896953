import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import cx from 'classnames'
import { useParams, usePathname } from 'next/navigation'
import { useEffect, useMemo, useState } from 'react'

import BottomTabsBar from 'components/BottomTabsBar'
import FreeSpinsBar from 'components/FreeSpinsBar'
import { Show } from 'components/Show'
import useDynamicElementOffset from 'components/StickyElementsContainer/useDynamicElementOffset'
import Chats from 'containers/Chats'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import { useAppDispatch } from 'store/hooks'
import { setIsStickyPlayButtonsShowed } from 'store/modules/appConfig/actions'
import ROUTES from 'temp/routes.json'
import { isGameInfoPage, isPlayPage } from 'utils/route'

import ScrollToTop from '../ScrollToTop'

import classes from './StickyElementsContainer.module.scss'

const GENERAL_RESTRICTED_ROUTES = [ROUTES.LANDING_PAGE, ROUTES.LOGIN, ROUTES.REGISTER]

export default function StickyElementsContainer() {
  const isMobile = useIsMobile()
  const [isScrollToTopShowed, setIsScrollToTopShowed] = useState(false)
  const [isTabsOpened, setIsTabsOpened] = useState(false)
  const dispatch = useAppDispatch()

  const isBottomTabsBar = useIsBottomTabsBar()
  const params = useParams()

  const pathname = usePathname()
  const isRouteRestricted = useMemo(() => GENERAL_RESTRICTED_ROUTES.includes(pathname), [pathname])
  const isGamePlayPage = isPlayPage(pathname)

  const showScrollToTopElement =
    !isRouteRestricted && ((isGamePlayPage && !isMobile) || !isGamePlayPage) && !isGameInfoPage(params, pathname)

  const offsetWhenStickyPlayButtons = useDynamicElementOffset(isTabsOpened, isScrollToTopShowed)

  useEffect(() => {
    setIsTabsOpened(false)
    setIsScrollToTopShowed(false)
    dispatch(setIsStickyPlayButtonsShowed({ isStickyPlayButtonsShowed: false }))
  }, [pathname])

  return (
    <>
      <div className={cx(classes.root, { [classes.mobile]: isMobile })}>
        <div className={classes.dynamicElements} style={{ transform: `translateY(-${offsetWhenStickyPlayButtons}px)` }}>
          <Show when={showScrollToTopElement}>
            <ScrollToTop handleButtonVisibility={setIsScrollToTopShowed} />
          </Show>
          {isBrowser() && <Chats />}
        </div>
        <Show when={isBottomTabsBar}>
          <BottomTabsBar
            handleTabsVisibility={setIsTabsOpened}
            freeSpins={<FreeSpinsBar className={classes.freeSpinsMobile} />}
          />
        </Show>
      </div>
      {/* TODO: Review approach for snackbar (B2SPIN-6271) */}
      <GlobalSnackbar />
    </>
  )
}

import { usePrevious } from '@patrianna/shared-hooks'
import { getAuthFlowLoadingSelector } from '@patrianna/shared-store/auth'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { useEffect } from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { appStateActiveHandler, appStateInactiveHandler } from 'services/websocket'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getAccountInfo } from 'store/modules/user/actions'

export default function VisibilityProvider(): null {
  const isVisible = usePageVisibility()
  const dispatch = useAppDispatch()
  const isPrevVisible = usePrevious(isVisible)
  const isAuthFlow = useAppSelector(getAuthFlowLoadingSelector)

  useEffect(() => {
    if (isAuthFlow) {
      return undefined
    }
    // tab becomes inactive
    if (!isVisible) {
      dispatch(appStateInactiveHandler())
    }

    // tab becomes active
    if (isBrowser() && isVisible) {
      dispatch(appStateActiveHandler())
      // tab in background
    }

    if (isBrowser() && !isPrevVisible && isVisible) {
      dispatch(getAccountInfo())
      // tab in background
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isVisible])

  return null
}

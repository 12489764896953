import { RouterChannel, RouterEvent } from '@patrianna-payments/mf-channels'

import { useRouter } from 'app/context/navigation'
import { useSubscribeAction } from 'services/pubsub_con'

export const useRouterSubscriptions = () => {
  const router = useRouter()

  useSubscribeAction<{ path: string }>(RouterChannel.Actions, RouterEvent.Push, (payload) => {
    router.push(payload.path)
  })

  useSubscribeAction<{ path: string; options?: { scroll?: boolean } }>(
    RouterChannel.Actions,
    RouterEvent.Replace,
    (payload) => {
      router.replace(payload.path, payload.options)
    }
  )
}

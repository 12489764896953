import { federation } from '@patrianna-payments/payment-remote-entry'
import React from 'react'
import ReactDOM from 'react-dom'

import RemoteEntryPayment from '.'

federation({
  name: 'host',
  shared: {
    react: {
      version: React.version,
      scope: 'default',
      lib: () => React,
      shareConfig: {
        singleton: true,
        requiredVersion: React.version,
      },
    },
    'react-dom': {
      version: ReactDOM.version,
      scope: 'default',
      lib: () => ReactDOM,
      shareConfig: {
        singleton: true,
        requiredVersion: ReactDOM.version,
      },
    },
  },
  remotes: [
    {
      name: 'payment',
      entry: `${process.env.PAYMENT_MF_HOST}/remoteEntry.js?updated=1`,
    },
  ],
})

function EmptyLoader() {
  return <></>
}

const RemoteEntryPaymentGlobal = () => {
  return (
    <>
      <RemoteEntryPayment module='RedeemEvents' CustomLoader={EmptyLoader} />
      <RemoteEntryPayment module='SoftKycSubscription' CustomLoader={EmptyLoader} />
      <RemoteEntryPayment module='OtpEvents' CustomLoader={EmptyLoader} />
    </>
  )
}

export default RemoteEntryPaymentGlobal

import { isBrowser } from '@patrianna/shared-utils'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getItemFormLocalStorageSelector, setDataToLocalStorage } from 'utils/localStorage'

function SaveWebReferrer(): null {
  const dispatch = useAppDispatch()
  const isFirstUtm = useAppSelector((state) => getItemFormLocalStorageSelector(state, 'FIRST_UTM', false))
  const isQuery = useAppSelector((state) => getItemFormLocalStorageSelector(state, 'QUERY', false))

  useEffect(() => {
    if (isBrowser()) {
      if (!document?.referrer?.includes(window.location.origin)) {
        dispatch(setDataToLocalStorage('REFERRER', document.referrer, false))
      }

      // first utm
      if (!isFirstUtm) {
        const searchString = new URLSearchParams(window.location?.search?.replace?.('?', ''))
        const timestamp = `&timestamp=${Date?.now?.()}`
        dispatch(
          setDataToLocalStorage(
            'FIRST_UTM',
            searchString?.has?.('utm_campaign')
              ? `${searchString.get('utm_campaign')}${timestamp}`
              : `blank${timestamp}`,
            false
          )
        )
      }

      // set query exclude auth flow
      if (!isQuery && !window.location.href.includes('access_token') && !window.location.href.includes('id_token')) {
        dispatch(setDataToLocalStorage('QUERY', window.location?.search?.replace?.('?', ''), false))
      }
    }
  }, [isFirstUtm, isQuery])

  return null
}

export default SaveWebReferrer

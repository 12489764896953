import {
  checkInvitationUserBonuses,
  checkManualUserBonuses,
  checkWeeklyRewardBonuses,
} from 'store/modules/bonusesFlow/actions'
import { checkInviteFriendsModalVisibility, checkLegalsModalVisibility } from 'store/modules/user/actions'

export const actionsMap = {
  checkManualUserBonuses,
  checkInvitationUserBonuses,
  checkLegalsModalVisibility,
  checkWeeklyRewardBonuses,
  checkInviteFriendsModalVisibility,
}

'use client'

import { useIsSafari } from '@patrianna/shared-hooks'

import usePWAInstance, { useIsPWAAvailable } from 'hooks/usePWA'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { togglePWAMessage } from 'store/modules/appConfig/actions'
import { getIsPWAMessageOpenSelector } from 'store/modules/appConfig/selectors'
import { CLOSE_AUTO_PWA_DIALOG, setDataToLocalStorage } from 'utils/localStorage'

import Android from './components/Android'
import IOS from './components/IOS'
import MessageLayout from './components/MessageLayout'
import classes from './components/styles.module.scss'

function Root() {
  const isPWAOpenMessage = useAppSelector(getIsPWAMessageOpenSelector)
  const dispatch = useAppDispatch()

  const pwaInstance = usePWAInstance()
  const isIOS = useIsSafari()

  const isPwaAvailable = useIsPWAAvailable()

  const closeMessage = () => {
    dispatch(setDataToLocalStorage(CLOSE_AUTO_PWA_DIALOG, true, false))
    dispatch(togglePWAMessage({ isPWAMessageOpen: false }))
  }

  if (!isPwaAvailable || !pwaInstance || !isPWAOpenMessage) {
    return null
  }

  if (isIOS && isPWAOpenMessage) {
    return (
      <MessageLayout className={classes.iosLayoutRoot} closeHandler={closeMessage}>
        <IOS />
      </MessageLayout>
    )
  }
  if (isPWAOpenMessage) {
    return (
      <MessageLayout closeHandler={closeMessage}>
        <Android pwaInstance={pwaInstance} buttonHandler={closeMessage} />
      </MessageLayout>
    )
  }

  return null
}

export default Root

import { AuthChannel, AuthEvent } from '@patrianna-payments/mf-channels'
import { useEffect, useState } from 'react'

import { useAuthCountries } from 'hooks/useAuthCountries'
import { pubSubManager, useSubscribeAction } from 'services/pubsub_con'
import type { CountryCode, SignUpCountry } from 'utils/countryCodes'

const authCountryChannel = pubSubManager.createDataChannel<Array<SignUpCountry>>(AuthChannel.CountrySync)

export const useAuthCountriesMF = () => {
  const [currentCountry, setCurrentCountry] = useState('')
  const { countries } = useAuthCountries(currentCountry as CountryCode)

  useSubscribeAction<string>(AuthChannel.CountrySync, AuthEvent.UpdateAuthCountry, (country) => {
    setCurrentCountry(country)
  })

  useEffect(() => {
    authCountryChannel.open()
    authCountryChannel.update(countries)

    return () => {
      authCountryChannel.close()
    }
  }, [countries])
}

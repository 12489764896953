import isWebView from 'is-ua-webview'
import { useEffect } from 'react'

const IS_NOT_LOCAL_HOST = process.env.NEXT_PUBLIC_LOCALHOST !== 'true'

const importPwaRegistration = async () => {
  // @ts-ignore
  await import('next-pwa/register')
}

const registerWorkbox = (): Promise<any> => {
  try {
    // @ts-ignore
    return window.workbox.register()
  } catch (error) {
    return Promise.reject(error)
  }
}

const registerWorkboxWithLog = async (isRetry = false) => {
  await importPwaRegistration()

  return registerWorkbox().catch((error) => {
    if (error === 'Rejected' || error?.message === 'Rejected') {
      return console.log(
        `Service Worker ${isRetry ? 'retry ' : ''}registration failed with an unexpected error: `,
        error
      )
    }

    console.error(`Service Worker ${isRetry ? 'retry ' : ''}registration failed with an unexpected error: `, error)
    throw error
  })
}

export default function useRegisterWorkbox() {
  useEffect(() => {
    // register service worker only on desktop and supported browsers
    if (IS_NOT_LOCAL_HOST && !isWebView(window.navigator.userAgent) && navigator?.serviceWorker) {
      // retry registration with timeout
      let retryTimerId: ReturnType<typeof setTimeout> = null
      registerWorkboxWithLog().catch(() => {
        retryTimerId = setTimeout(registerWorkboxWithLog, 3000, true)
      })

      return () => clearTimeout(retryTimerId)
    }
  }, [])
}

import { SnackbarContent } from '@patrianna/core-components'
import type { MessageAlign, SnackbarVariants } from '@patrianna/shared-patrianna-types/store/SnackbarModule'
import cx from 'classnames'

import AnimatedOverlay from 'dialogs/components/AnimatedOverlay'
import { useAppDispatch } from 'store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'

import RichMessage from './RichMessage'
import classes from './styles.module.scss'

export interface SnackbarProps {
  className?: string
  message?: string
  variant: SnackbarVariants
  action?: (...args: unknown[]) => unknown
  messageAlign: MessageAlign
}

function Snackbar(props: SnackbarProps) {
  const { message, variant, messageAlign, action, ...other } = props

  const dispatch = useAppDispatch()

  const handleOverlayClick = () => {
    action?.()
    dispatch(closeSnackbar())
  }

  return (
    <AnimatedOverlay
      mobileDirection='center'
      desktopDirection='center'
      overlayHandler={handleOverlayClick}
      overlayClass={cx('scrollModal', classes.overlay)}
    >
      <SnackbarContent
        className={cx(classes[messageAlign], classes.modalLikeSnackbarContent)}
        message={<RichMessage variant={variant} message={message} action={action} />}
        {...other}
      />
    </AnimatedOverlay>
  )
}

export default Snackbar

import cx from 'classnames'
import type { Dispatch, SetStateAction } from 'react'
import React, { useRef, useState } from 'react'

import classes from 'components/FreeSpinsBar/FreeSpinsBar.module.scss'
import { useInView } from 'hooks/useInView'

type Props = {
  text: string
  className?: string
  onAnimate?: Dispatch<SetStateAction<boolean>>
  skipInViedCheck?: boolean
}

export const RunningText = ({ text, className, onAnimate, skipInViedCheck }: Props) => {
  const [animate, setAnimate] = useState(false)
  const textRef = useRef<HTMLDivElement>()
  const rootRef = useRef<HTMLDivElement>()
  const { ref: refInView } = useInView({
    threshold: 0.6,
    triggerOnce: false,
    onChange: function (inView) {
      if ((inView || skipInViedCheck) && textRef?.current?.offsetWidth > rootRef?.current?.offsetWidth) {
        setAnimate(true)
        onAnimate?.(true)
      } else {
        setAnimate(false)
        onAnimate?.(false)
      }
    },
  })

  return (
    <div
      ref={(node) => {
        refInView(node)
        rootRef.current = node
      }}
      className={cx(classes.runningText, className)}
    >
      <div
        ref={textRef}
        className={cx(classes.text, {
          [classes.slideText]: animate,
        })}
      >
        {text}
      </div>
      {animate && (
        <div
          className={cx(classes.text, {
            [classes.slideText]: animate,
          })}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default RunningText

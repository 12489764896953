import { usePrevious } from '@patrianna/shared-hooks'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getUserFirstPurchaseSelector,
  shouldShowInviteFriendsModalForPaidUsers,
  shouldShowInviteFriendsModalForUnpaidUsers,
} from 'store/modules/user/selectors'
import { INVITE_FRIENDS_HIDDEN, getItemFormLocalStorageSelector, setDataToLocalStorage } from 'utils/localStorage'

export default function useIsInviteFriendsAvailable() {
  const dispatch = useAppDispatch()
  const inviteFriendsLastDateOpened = useAppSelector((state) =>
    getItemFormLocalStorageSelector(state, INVITE_FRIENDS_HIDDEN, false)
  )
  const showForPaidUsers = useAppSelector((state) =>
    shouldShowInviteFriendsModalForPaidUsers(state, inviteFriendsLastDateOpened)
  )
  const showForUnpaidUsers = useAppSelector(shouldShowInviteFriendsModalForUnpaidUsers)
  const userFirstPurchase = useAppSelector(getUserFirstPurchaseSelector)
  const prevUserFirstPurchase = usePrevious(userFirstPurchase)

  useEffect(() => {
    const currentDate = new Date()
    const isFriday = currentDate.getDay() === 5

    // when first payment is made on Friday and modal had already been opened
    // in current session - do not open it again during the same session
    if (!prevUserFirstPurchase && userFirstPurchase && isFriday && sessionStorage.getItem(INVITE_FRIENDS_HIDDEN)) {
      dispatch(setDataToLocalStorage(INVITE_FRIENDS_HIDDEN, currentDate, false))
      sessionStorage.removeItem(INVITE_FRIENDS_HIDDEN)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [userFirstPurchase])

  return showForPaidUsers || (showForUnpaidUsers && !sessionStorage.getItem(INVITE_FRIENDS_HIDDEN))
}

import { Loader } from '@patrianna/core-components'
import { IconButton } from '@patrianna/core-components'
import { useTranslation } from '@patrianna/core-components'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import { Close } from 'mdi-material-ui'
import { usePathname } from 'next/navigation'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import EmptyTile from 'components/BottomTabsBar/EmptyTile'
import SlotsRowContainer from 'components/BottomTabsBar/SlotsRowContainer'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import { useAppSelector } from 'store/hooks'
import { getFavoriteGamesSelector } from 'store/modules/categoryGames'
import { getRecentlyPlayedGamesSelector, isCategoryGamesLoadedSelector } from 'store/modules/categoryGames/selectors'
import ROUTES from 'temp/routes.json'

import classes from './BottomTabsBar.module.scss'

const LAST_PLAYED = 'last_played' as const
const FAVOURITE = 'favourite' as const
const ICON_SIZE = 20

type TabKey = typeof LAST_PLAYED | typeof FAVOURITE

type Tabs = Record<
  TabKey,
  {
    title: string
    emptyTitle: string
    games: GameProductShort[]
    icon: string
  }
>

const tabs: Tabs = {
  [LAST_PLAYED]: {
    title: 'common.recently_played',
    games: [],
    icon: '/menu/recently_played',
    emptyTitle: 'common.no_recently_items',
  },
  [FAVOURITE]: {
    title: 'common.favorites',
    games: [],
    icon: '/common/favorite',
    emptyTitle: 'common.no_favourites_items',
  },
}

type Props = {
  freeSpins: ReactNode
  handleTabsVisibility?: (isTabOpened: boolean) => void
}

const FREESPINS_BAR_ENABLED = process.env.FREESPINS_BAR_ENABLED

export default function BottomTabsBar({ freeSpins, handleTabsVisibility }: Props) {
  const isMobile = useIsMobile()
  const t = useTranslation()

  const [isOpened, setIsOpened] = useState(false)
  const [activeTab, setActiveTab] = useState<TabKey>(LAST_PLAYED)
  const isCategoryGamesLoaded = useAppSelector(isCategoryGamesLoadedSelector)
  const recentlyPlayedGames = useAppSelector(getRecentlyPlayedGamesSelector)
  const favouriteGames = useAppSelector(getFavoriteGamesSelector)
  const isBottomTabsBar = useIsBottomTabsBar()
  const pathname = usePathname()
  const isHome = pathname === ROUTES.INDEX

  tabs[LAST_PLAYED].games = recentlyPlayedGames
  tabs[FAVOURITE].games = favouriteGames

  const games = tabs[activeTab].games

  useEffect(() => {
    if (!recentlyPlayedGames.length && favouriteGames.length) {
      setActiveTab(FAVOURITE)
    }
  }, [recentlyPlayedGames.length, favouriteGames.length])

  const tabClickHandler = (tab: TabKey) => {
    if (tab !== activeTab || !isOpened) {
      setActiveTab(tab)
      setIsOpened(true)
      handleTabsVisibility?.(true)
    } else {
      setIsOpened(false)
      handleTabsVisibility?.(false)
    }
  }

  const handleClose = () => {
    setIsOpened(false)
    handleTabsVisibility?.(false)
  }

  if (!isBottomTabsBar) {
    return FREESPINS_BAR_ENABLED && isHome ? (
      <section className={cx(classes.bottomTabsBar, !isMobile && classes.desktop)}>{freeSpins}</section>
    ) : null
  }

  return (
    <section className={cx(classes.bottomTabsBar, !isMobile && classes.desktop)} data-component='bottom-tabs-bar'>
      {FREESPINS_BAR_ENABLED && freeSpins}
      <div className={cx(classes.accordionHeader, !isOpened && isMobile && classes.accordionHeaderAligned)}>
        {Object.entries(tabs).map(([tabKey, tabMeta]) => {
          return (
            <IconButton
              key={tabKey}
              className={cx(
                classes.tabButton,
                activeTab === tabKey && isOpened && classes.active,
                isMobile && classes.buttonAligned
              )}
              onClick={() => {
                tabClickHandler(tabKey as TabKey)
              }}
              aria-controls={activeTab}
              aria-expanded={isOpened && activeTab === tabKey}
            >
              <img src={`${process.env.IMG_ORIGIN}${tabMeta.icon}.svg`} width={ICON_SIZE} height={ICON_SIZE} alt='' />
              <span>{t(tabMeta.title)}</span>
              <img
                className={isOpened && activeTab === tabKey ? classes.toggle_down : classes.toggle_up}
                src={`${process.env.IMG_ORIGIN}/menu/accordion_down.svg`}
                width={ICON_SIZE}
                height={ICON_SIZE}
                alt=''
              />
            </IconButton>
          )
        })}
        {isOpened && !isMobile && (
          <IconButton
            aria-label={`${t('common.close')} ${t(tabs[activeTab].title)}`}
            aria-controls={activeTab}
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        )}
      </div>
      <div id={activeTab} className={cx(classes.accordionBody, { [classes.expanded]: isOpened })}>
        {!isCategoryGamesLoaded && activeTab === LAST_PLAYED && !recentlyPlayedGames.length ? (
          <Loader className={classes.loader} />
        ) : games?.length ? (
          <SlotsRowContainer key={activeTab} games={games} numberOfPreLoadedItems={isMobile ? 10 : 20} />
        ) : (
          <EmptyTile>{t(tabs[activeTab].emptyTitle)}</EmptyTile>
        )}
      </div>
    </section>
  )
}

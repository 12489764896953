import { useState, useRef, useEffect, type MutableRefObject } from 'react'

export default function useScrollable(): [MutableRefObject<HTMLElement>, boolean] {
  const [isScrollable, setIsScrollable] = useState(true)
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    function handleScrollable() {
      if (ref.current) {
        setIsScrollable(ref.current.scrollWidth > ref.current.clientWidth)
      }
    }

    handleScrollable()
  }, [])

  return [ref, isScrollable]
}

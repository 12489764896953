import type { GetAccountSettingsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountSettingsResponse } from '@patrianna/shared-patrianna-types/websocket/response'

import type { TypedThunk } from 'store'
import { savePasswordPolicy, saveRedeemPolicy } from 'store/modules/appConfig/actions'

const getAccountSettingsRequest =
  (): TypedThunk =>
  (dispatch, getState, { errorHandler, api }) => {
    const data: GetAccountSettingsRequest = {
      type: 'GetAccountSettingsRequest',
    }

    api
      .getAccountSettings({})
      .then((body: GetAccountSettingsResponse) => {
        dispatch(
          saveRedeemPolicy({
            policy: {
              sweepstakeRedeemPolicy: body.sweepstakeRedeemPolicy,
              fiatRedeemPolicy: body.fiatRedeemPolicy,
              nonMonetaryRedeemPolicy: body.nonMonetaryRedeemPolicy,
            },
          })
        )
        dispatch(savePasswordPolicy({ passwordPolicy: body.passwordPolicy }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export default getAccountSettingsRequest

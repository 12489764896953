import { IconButton } from '@patrianna/core-components'
import cx from 'classnames'
import type { ReactElement } from 'react'
import { cloneElement, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import classes from 'components/FreeSpinsBar/FreeSpinsBar.module.scss'
import { getInitialTrack } from 'components/FreeSpinsBar/utils'

type Props = {
  children: ReactElement[]
  transitionTime?: number
}

const Carousel = ({ children, transitionTime = 1400 }: Props) => {
  const [isAnimate, setIsAnimate] = useState(false)
  const [track, setTrack] = useState([])
  const timerRef = useRef(null)

  useEffect(() => {
    setTrack(getInitialTrack(children))
  }, [children])

  const sliderClickHandler = () => {
    if (!isAnimate) {
      setIsAnimate(true)
      timerRef.current = setTimeout(() => {
        setTrack([...track.slice(1), cloneElement(track[0], { key: track[0].key + uuid() })])
        setIsAnimate(false)
      }, transitionTime)
    }
  }

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  return (
    <div className={classes.sliderHolder}>
      <div
        className={cx(classes.track, { [classes.oneItemTrack]: track.length === 1 }, { [classes.animate]: isAnimate })}
        style={isAnimate ? { transition: `transform ${transitionTime}ms` } : {}}
      >
        {track}
      </div>

      {track.length > 1 && <IconButton className={cx(classes.carouselButton)} onClick={sliderClickHandler} />}
    </div>
  )
}

export default Carousel

import { useEffect, useState } from 'react'

export const MULTI_STEP = 'multistep'

type Experiment = {
  id: string
  name: string | null
  variation: {
    id: string
    name: string | null
  }
}

type Experiments = {
  [key: string]: Experiment
}

export const useABTest = (variationName: string) => {
  const [isTestActive, setIsTestActive] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.optimizelyEdge) {
      const experiments: Experiments = window.optimizelyEdge.get?.('state')?.getActiveExperiments?.() || {}
      const variationsList = Object.values(experiments).map((item) => item?.variation?.name || '')
      const isActive = variationsList.includes(variationName)
      setIsTestActive(isActive)
    }
    setIsLoaded(true)
  }, [variationName])

  return [isTestActive, isLoaded]
}

export const useManualABTest = (variationName: string, testId: string) => {
  const [isTestActive, setIsTestActive] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isOptimizelyInited, setIsOptimizelyInited] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.optimizelyEdge) {
      if (window?.optimizely?.initialized) {
        setIsOptimizelyInited(true)
      } else {
        window.optimizelyEdge.push({
          type: 'addListener',
          filter: {
            type: 'lifecycle',
            name: 'initialized',
          },
          handler() {
            setIsOptimizelyInited(true)
          },
        })
      }
    } else {
      setIsLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.optimizelyEdge?.push({
        type: 'page',
        pageName: testId,
        isActive: true,
      })
    }
  }, [testId])

  useEffect(() => {
    if (isOptimizelyInited) {
      const experiments: Experiments = window.optimizelyEdge.get?.('state')?.getActiveExperiments?.() || {}
      const variationsList = Object.values(experiments).map((item) => item?.variation?.name || '')
      const isActive = variationsList.includes(variationName)
      setIsTestActive(isActive)
      setIsLoaded(true)
    }
  }, [isOptimizelyInited, variationName])

  return [isTestActive, isLoaded]
}

export type CountryCode = (typeof countryCodes)[number]

export interface SignUpCountry {
  value: CountryCode
  name: string
}

export const countryCodes = [
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CO',
  'CZ',
  'DE',
  'DK',
  'ES',
  'ET',
  'FI',
  'FR',
  'GH',
  'GR',
  'HK',
  'HR',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IT',
  'JP',
  'KE',
  'LT',
  'LV',
  'MY',
  'MZ',
  'NG',
  'NL',
  'NO',
  'NZ',
  'PA',
  'PE',
  'PH',
  'PT',
  'RO',
  'RS',
  'SE',
  'SK',
  'TH',
  'TW',
  'TZ',
  'US',
  'VN',
  'ZA',
] as const

export function transformCountries(data: CountryCode[], t: any): SignUpCountry[] {
  const result = data.map((item) => ({ value: item, name: t(`authPages.country_${item}`) }))

  return [{ value: '' as CountryCode, name: t('authPages.select_country') }, ...result]
}

import type {
  JackpotWinNotificationNew,
  NotifyResponses,
} from '@patrianna/shared-patrianna-types/websocket/notifications'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { listenPurchaseNotification } from '@patrianna-payments/shared-store/payments/store/actions'
import { offerDeclineNotificationHandler } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import { getQuickPurchaseSupportedTypeSelector } from '@patrianna-payments/shared-store/payments/store/selectors'
import { setOTPMandatory, setOTPTrigger, setSkipOTP, setSkipOtpClosed } from '@patrianna-payments/shared-store/skip-otp'
import { batch } from 'react-redux'

import { pubSubManager } from 'services/pubsub_con'
import type { TypedThunk } from 'store'
import { saveOrGetPaymentProviders } from 'store/modules/appConfig/actions'
import { logOut, logoutRequest } from 'store/modules/auth/actions'
import {
  updateInvitationBonusesNotificationListener,
  updateManualBonusesNotificationListener,
} from 'store/modules/bonusesFlow/actions'
import { updateCurrenciesBalance } from 'store/modules/currencies/actions'
import { closeAllDialogs, openDialog, removeDialogByName } from 'store/modules/dialog/actions'
import { saveFreeSpins } from 'store/modules/freeSpins/actions'
import {
  getAccountJackpotInfo,
  openOptInNowDialog,
  openSpinWheelDialog,
  patchJackpotTotals,
  setContibutionsJackpot,
  setJackpotUpdateInterval,
} from 'store/modules/jackpots/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import {
  getAccountInfo,
  getAccountInfoAndUpdateVerificationData,
  setIsEmailVerified,
  setKycStatus,
  setLevelProgress,
  setUser,
  trackKYCConfirmedEvent,
} from 'store/modules/user/actions'
import { getUserSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

const crmEventWebSocketChannel = pubSubManager.createActionChannel('CRM_WEBSOCKET_NOTIFICATION_CHANNEL')

const wsNotifyHandler =
  ({ body }: { body: NotifyResponses }): TypedThunk =>
  (dispatch, getState) => {
    batch(() => {
      // fire custom event for payment mf
      // for now we need it only for SkipOtpChangeNotification but in future it might be changed
      crmEventWebSocketChannel.open()
      crmEventWebSocketChannel.publish('CRM_WEBSOCKET_NOTIFICATION_EVENT', body)
      crmEventWebSocketChannel.close()

      switch (body.type) {
        case 'BalanceChangeNotification': {
          dispatch(updateCurrenciesBalance({ currency: body.balances }))
          break
        }

        case 'XPLevelChangeNotification': {
          dispatch(setLevelProgress(body))
          break
        }

        case 'OfferPurchaseNotification': {
          const pathname = window?.location?.pathname
          const { isQuickPurchase, isQuickPurchaseGetCoins } = getQuickPurchaseSupportedTypeSelector(
            getState(),
            pathname
          )

          if (!(isQuickPurchase || isQuickPurchaseGetCoins)) {
            dispatch(listenPurchaseNotification(body))
          }

          break
        }

        case 'SignOutNotification': {
          dispatch(logOut())

          break
        }

        case 'KYCDocSuccessUploadNotification': {
          dispatch(removeDialogByName({ modalName: 'VERIFICATION_KYC_DIALOG' }))
          dispatch(setKycStatus({ kycStatus: 'doc_review' }))

          const query = new URLSearchParams(window?.location?.search)

          dispatch(
            openDialog({
              modalName: 'VERIFICATION_IN_PROGRESS_DIALOG',
              dialogProps: { verificationFrom: query?.get('verificationFrom') || '' },
            })
          )

          break
        }

        case 'KYCDocErrorUploadNotification': {
          dispatch(
            openSnackbar({
              message: body.reason || 'Error uploading documents',
            })
          )
          break
        }

        case 'KYCConfirmNotification': {
          // @TODO hotfix will change it after the release
          dispatch(
            openSnackbar({
              message: isBrowser()
                ? // @ts-ignore
                  window.__FORM_VALIDATION_TRANSLATIONS__?.verification?.id_confirmed || 'Id confirmed'
                : 'Id confirmed',
              variant: 'success',
            })
          )
          dispatch(getAccountInfoAndUpdateVerificationData('id_confirmed'))
          dispatch(trackKYCConfirmedEvent('ID_Verification_Success'))
          break
        }

        case 'KYCDocConfirmNotification': {
          dispatch(
            openSnackbar({
              message: isBrowser()
                ? // @ts-ignore
                  window.__FORM_VALIDATION_TRANSLATIONS__?.verification?.document_confirmed || 'Document confirmed'
                : 'Document confirmed',
              variant: 'success',
            })
          )
          dispatch(getAccountInfoAndUpdateVerificationData('confirmed'))
          dispatch(trackKYCConfirmedEvent('POA_Verification_Success'))
          break
        }

        case 'KYCInReviewNotification': {
          dispatch(setKycStatus({ kycStatus: 'in_review' }))
          break
        }

        case 'KYCDocBlockNotification':
        case 'KYCBlockNotification': {
          dispatch(setKycStatus({ kycStatus: 'blocked' }))
          dispatch(closeAllDialogs())

          // @TODO will change to home page
          dispatch(
            logoutRequest(
              `${ROUTES.INDEX}?dialogName=LOCK_USER_DIALOG&dialogProps.reason=Your account has failed verification.`
            )
          )
          break
        }

        case 'ShowSystemMessageNotification': {
          if (body.markdown) {
            dispatch(
              openSnackbar({
                message: body.markdown,
              })
            )
          }
          break
        }

        case 'AccountBlockNotification': {
          dispatch(closeAllDialogs())
          if (body?.reloginProvider) {
            dispatch(
              logoutRequest(
                `${ROUTES.INDEX}?dialogName=OTP_HELLO_AGAIN_DIALOG&dialogProps.reloginProvider=${body.reloginProvider}${
                  body?.reloginEmail ? `&dialogProps.reloginEmail=${body.reloginEmail}` : ''
                }`
              )
            )
          } else {
            dispatch(openDialog({ modalName: 'LOCK_USER_DIALOG', dialogProps: { singletone: true } }))
          }
          break
        }

        case 'KYCDocDeclineNotification': {
          dispatch(setKycStatus({ kycStatus: 'doc_declined' }))
          break
        }

        case 'KYCDeclineNotification': {
          dispatch(setKycStatus({ kycStatus: 'declined' }))
          break
        }

        case 'AcceptManualBonusNotification': {
          // eslint-disable-next-line
          const { type, ...rest } = body
          dispatch(updateManualBonusesNotificationListener(rest))
          break
        }

        case 'AcceptInvitationBonusNotification': {
          // eslint-disable-next-line
          const { type, ...rest } = body
          dispatch(updateInvitationBonusesNotificationListener(rest))
          // backward compatibility
          if (rest?.account) {
            dispatch(setUser({ data: rest.account }))
          }
          break
        }

        case 'OfferRefundNotification': {
          dispatch(
            openSnackbar({
              message: 'Your purchase has been refunded: Please see email for more information.',
            })
          )
          break
        }

        case 'OfferDeclineNotification': {
          const { isQuickPurchase, isQuickPurchaseGetCoins } = getQuickPurchaseSupportedTypeSelector(
            getState(),
            window.location.pathname
          )

          if (!(isQuickPurchase || isQuickPurchaseGetCoins)) {
            dispatch(offerDeclineNotificationHandler(body))
          }

          break
        }

        case 'EmailVerifiedNotification': {
          dispatch(setIsEmailVerified({ isVerified: true }))
          window.location.href = ROUTES.INDEX
          break
        }

        case 'SkipOtpChangeNotification': {
          dispatch(setSkipOTP({ skipOtp: body?.skipOtp }))
          dispatch(setOTPMandatory({ mandatory: body?.mandatory }))
          dispatch(setOTPTrigger({ trigger: body?.trigger }))
          dispatch(setSkipOtpClosed({ closed: false }))
          break
        }

        case 'JackpotOptOutNotification': {
          break
        }

        case 'JackpotWinNotification': {
          const { amount, subtype: code, jackpotId, currency, spinAmount } = body as JackpotWinNotificationNew
          // @ts-ignore
          dispatch(openSpinWheelDialog({ amount, code, jackpotId, currency, spinAmount }))
          dispatch(getAccountJackpotInfo())
          break
        }

        case 'JackpotContributionNotification': {
          dispatch(setContibutionsJackpot(body.contributions))
          break
        }

        case 'JackpotUpdateNotification': {
          dispatch(patchJackpotTotals(body.preferences))
          dispatch(setJackpotUpdateInterval(body.updateInterval))
          dispatch(openOptInNowDialog(body.preferences))
          break
        }

        case 'UpdatePaymentMethodsNotification': {
          const { withdrawProviders, purchaseProviders } = body

          dispatch(saveOrGetPaymentProviders({ withdrawProviders, purchaseProviders }))

          break
        }

        case 'CreateFreeSpinNotification': {
          const userInfo = getUserSelector(getState())

          if (userInfo && userInfo?.emailVerified) {
            dispatch(saveFreeSpins({ freeSpins: body.freeSpins }))
            if (!process.env.FREESPINS_BAR_ENABLED) {
              dispatch(openDialog({ modalName: 'FREE_SPINS_DIALOG', dialogProps: { freeSpins: body.freeSpins } }))
            }
          }

          break
        }

        case 'ModeChangeNotification': {
          dispatch(getAccountInfo(true))

          break
        }

        default: {
          if (process.env.PROFILE !== 'prod') {
            // eslint-disable-next-line
            console.log(`%c${`Unhandled notify event - ${body}`}`, 'color:Orange', body)
          }
          break
        }
      }
    })
  }

export default wsNotifyHandler

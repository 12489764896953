'use client'

import type {
  GameProductShort,
  SwimlaneCategoryStyle,
  TagsType,
} from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import { useState } from 'react'

import { Link } from 'components/Link'
import { Picture } from 'components/Picture'
import type { PictureProps } from 'components/Picture/Picture.types'
import { ImageType } from 'components/Picture/Picture.types'
import ProductTileTags from 'components/ProductTileTags'
import { useAppDispatch } from 'store/hooks'
import { closeAllDialogs } from 'store/modules/dialog/actions'
import images from 'temp/images.json'
import { getSlotStartUrlByGameTags } from 'utils/navigator/games'

import classes from './RoundTilesExperiment.module.scss'
import RunningText from './RunningText'

export type Props = {
  game: GameProductShort
  className?: string
  moreRows?: boolean
  handleTileClick?: (gameCode: string) => void
  isSearch?: boolean
  style?: SwimlaneCategoryStyle
}

function RoundProductTile({ game, className, handleTileClick, isSearch, style }: Props) {
  const dispatch = useAppDispatch()
  const url = `${getSlotStartUrlByGameTags(game.tags)}/${game.route}`

  const [animated, setAnimated] = useState(false)

  const pictureProps: PictureProps = {
    src: `${images.bucket}/tiles/${game.code}/source.png`,
    alt: game.title,
    className: classes.image,
    type: isSearch ? ImageType.SEARCH : ImageType.TILE,
    loading: 'lazy',
  }

  return (
    <Link
      href={url}
      className={cx(
        classes.root,
        classes.rootVisible,
        className && {
          [className]: className,
        }
      )}
      onClick={() => {
        handleTileClick?.(game.route)
        dispatch(closeAllDialogs())
      }}
    >
      <div className={cx(classes.imageContainer, classes.roundTileContainer)} data-test={`game-tile-${game.code}`}>
        <div className={classes.slotTileRound}>
          <Picture {...pictureProps} />
        </div>
        <div className={classes.roundTags}>
          <ProductTileTags tags={game.tags as TagsType[] & 'HJR'} tagSize={style} />
        </div>
        <RunningText
          text={game.title}
          className={cx(classes.runText, { [classes.centered]: !animated })}
          onAnimate={setAnimated}
          skipInViedCheck
        />
      </div>
    </Link>
  )
}

export default RoundProductTile

import { useTranslation } from '@patrianna/core-components'
import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/SlotGameFlowModule'
import { useRef } from 'react'

import Button from 'components/Button'
import RunningText from 'components/FreeSpinsBar/components/RunningText'
import classes from 'components/FreeSpinsBar/FreeSpinsBar.module.scss'
import { getFreeSpinText } from 'components/FreeSpinsBar/utils'

type Props = {
  freeSpin: FreeSpinType
  title: string
  onClickItem: (currentFreeSpin: FreeSpinType) => void
}

const FreeSpinBarItem = ({ freeSpin, title, onClickItem }: Props) => {
  const t = useTranslation()
  const text = getFreeSpinText(freeSpin, t)
  const textContainerRef = useRef<HTMLDivElement>()

  return (
    <div className={classes.freeSpinsItem}>
      <div ref={textContainerRef} className={classes.content}>
        <div className={classes.title}>{title}</div>
        <RunningText text={text} />
      </div>
      <Button
        className={classes.playButton}
        onClick={() => {
          onClickItem(freeSpin)
        }}
      >
        {t('commonModal.play_now').toLowerCase()}
      </Button>
    </div>
  )
}

export default FreeSpinBarItem

import { Direction, Slide } from '@patrianna/core-components'
import cx from 'classnames'
import type { ReactNode } from 'react'

import Close from './Close'
import classes from './styles.module.scss'

type Props = {
  children: ReactNode
  className?: string
  closeHandler: () => void
}
export default function MessageLayout(props: Props) {
  return (
    <Slide direction={Direction.Up} in className={classes.slide}>
      <div className={cx(classes.root, props.className)}>
        {props.children}
        <Close onClick={props.closeHandler} />
      </div>
    </Slide>
  )
}

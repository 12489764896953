import { useCallback, useEffect, useState } from 'react'

import type { UseHorizontalScrollArrowsParams } from '../HorizontalScroll.types'

export const useHorizontalScrollArrows = ({
  checkArrowsVisibility,
  showArrows,
  wrapperRef,
}: UseHorizontalScrollArrowsParams) => {
  const [isArrowsShown, setShowArrows] = useState(false)

  const onMouseOver = useCallback(() => setShowArrows(true), [])
  const onMouseOut = useCallback(() => setShowArrows(false), [])

  useEffect(() => {
    setShowArrows(showArrows)

    if (showArrows) {
      checkArrowsVisibility()
    }
  }, [checkArrowsVisibility, showArrows])

  useEffect(() => {
    const scroll: HTMLDivElement | undefined = wrapperRef.current

    if (showArrows) {
      scroll?.addEventListener('mouseover', onMouseOver)
      scroll?.addEventListener('mouseout', onMouseOut)
    }

    return () => {
      scroll?.removeEventListener('mouseover', onMouseOver)
      scroll?.removeEventListener('mouseout', onMouseOut)
    }
  }, [onMouseOut, onMouseOver, showArrows, wrapperRef])

  return {
    isArrowsShown,
  }
}

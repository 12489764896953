import ROUTES from 'temp/routes.json'

export const isSearchPage = (route: string) => {
  return `${route}/` === ROUTES.SEARCH
}

export const isShopPage = (route: string) => {
  return `${route}` === ROUTES.SHOP
}

export const isRecentlyPlayedPage = (route?: string) => {
  return `${route}` === ROUTES.RECENTLY_PLAYED
}

export const isPlayPage = (pathname?: string) => {
  if (pathname === undefined) {
    return false
  }

  // Checking for '/play' as a full segment not followed by additional characters except a slash or end of string
  return /\/play($|\/)/.test(pathname)
}

export const isGameInfoPage = (params: { [key: string]: string | string[] }, pathname?: string) => {
  if (pathname === undefined) {
    return false
  }

  return pathname.includes(ROUTES.GAMES_SLOTS) && !pathname.includes('/play') && params.gameId
}

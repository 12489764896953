import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import { useAppSelector } from 'store/hooks'
import { getIsStickyPlayButtonsShowedSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getSnackbarVisibilitySelector } from 'store/modules/snackbar/selectors'

const scrollButtonOffset = 64
const openedTabsOffset = 130
const tabsOffset = 58
const mobileBarOffset = 64
const snackBarOffset = 50
const oneStickyPlayButtonOffset = 86
const twoStickyPlayButtonsOffset = 115

const useDynamicElementOffset = (isTabsOpened: boolean, isScrollToTopShowed: boolean) => {
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const isBottomTabsBar = useIsBottomTabsBar()
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const isStickyPlayButtons = useAppSelector(getIsStickyPlayButtonsShowedSelector)
  const isSnackbarVisible = useAppSelector(getSnackbarVisibilitySelector)
  const stickyPlayButtonsOffset = isScEnabled ? twoStickyPlayButtonsOffset : oneStickyPlayButtonOffset
  const offsetWhenStickyPlayButtons = isStickyPlayButtons ? stickyPlayButtonsOffset : 0

  useEffect(() => {
    const chatbotStyle = document.createElement('style')
    const snackbar = document.getElementById('confirmation-message-snackbar')?.firstElementChild as HTMLElement
    let totalOffset = isMobile ? mobileBarOffset : 0
    let snackOffset = 0

    if (isBottomTabsBar) {
      totalOffset += tabsOffset
    }
    if (isTabsOpened) {
      totalOffset += openedTabsOffset
    }
    if (isScrollToTopShowed) {
      totalOffset += scrollButtonOffset
      snackOffset += scrollButtonOffset
    }
    if (isStickyPlayButtons) {
      totalOffset += offsetWhenStickyPlayButtons
      snackOffset += offsetWhenStickyPlayButtons
    }
    if (snackbar) {
      snackbar.style.transform = `translateY(-${snackOffset && snackOffset - 10}px)`
      snackbar.style.transition = 'transform 0.5s'
      snackbar.style.animation = '500ms ease-out forwards'
      totalOffset += snackBarOffset
    }

    chatbotStyle.innerHTML = `
          div#chatbot-chat {
            bottom: ${totalOffset}px !important;
          }
            html.chatbot-mobile-opened #chatbot-chat {
            bottom: 0px!important;
          }
        `
    document.head.appendChild(chatbotStyle)

    return () => {
      if (document.head.contains(chatbotStyle)) {
        document.head.removeChild(chatbotStyle)
      }
    }
  }, [
    isMobile,
    isScrollToTopShowed,
    isTabsOpened,
    isStickyPlayButtons,
    offsetWhenStickyPlayButtons,
    isBottomTabsBar,
    pathname,
    isScEnabled,
    isSnackbarVisible,
  ])

  return offsetWhenStickyPlayButtons
}

export default useDynamicElementOffset

import { useTranslation } from '@patrianna/core-components'
import type { PWAEventType } from '@patrianna/shared-patrianna-types/store/AppConfigModule'

import Button from 'components/Button'

import classes from './styles.module.scss'

type Props = {
  pwaInstance: PWAEventType | { prompt: () => void }
  buttonHandler: () => void
}

export default function Android(props: Props) {
  const t = useTranslation()

  return (
    <div className={classes.text}>
      {t('snapBarPWA.install_this_web_app')}
      <Button
        className={classes.button}
        size='small'
        variant='contained'
        onClick={() => {
          props.pwaInstance.prompt()
          props.buttonHandler()
        }}
      >
        {t('snapBarPWA.install_app')}
      </Button>
    </div>
  )
}

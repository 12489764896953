import useIsInviteFriendsAvailable from 'hooks/useIsInviteFriendsAvailable'
// import { getItemFormLocalStorage } from 'utils/localStorage'
import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  getWeeklyRewardBonusesSelector,
  isInvitationBonusAvailableSelector,
  isManualBonusAvailableSelector,
  isDailyBonusAvailableWithCommonConditionsAndGCGeneratorSelector,
  isSocialMediaRewardAvailableSelector,
} from 'store/modules/bonusesFlow/selectors'
// import { getJackpotMyStashWinSelector } from 'store/modules/jackpots/selectors'
import { isSignUpSessionSelector } from 'store/modules/dialog/selectors'
import { getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import { isOptInNowAvailableSelector } from 'store/modules/jackpots/selectors'
import { isOffersAvailableSelector } from 'store/modules/shop/selectors'
import {
  isQuickStartAvailable,
  shouldForceVerifyKYCSelector,
  skipUpdateLegalsSCDisabledSelector,
  skipUpdateLegalsScEnabledSelector,
} from 'store/modules/user/selectors'
import { getItemFormLocalStorageSelector, IS_LIVE_CASINO_SHOWN } from 'utils/localStorage'

import type { SessionDialogsFlags } from './types'

const isJackpotEnabled = process.env.JACKPOT_ENABLED

// Get all flags for session dialogs
export const useSessionDialogFlags = (): SessionDialogsFlags => {
  const isQuickStart = useAppSelector(isQuickStartAvailable)
  // const wasOpenedPlayerSafety = getItemFormLocalStorage('PLAYER_SAFETY_DIALOG')
  const isOffersAvailable = useAppSelector(isOffersAvailableSelector)
  // const myStashWin = useAppSelector(getJackpotMyStashWinSelector)
  const sweepstakeEnabled = useAppSelector(sweepstakeEnabledSelector)
  const skipUpdateLegalsScEnabled = useAppSelector(skipUpdateLegalsScEnabledSelector)
  const skipUpdateLegalsSCDisabled = useAppSelector(skipUpdateLegalsSCDisabledSelector)
  const isLegalsUpdatedAvailable = !(sweepstakeEnabled ? skipUpdateLegalsScEnabled : skipUpdateLegalsSCDisabled)
  const isRewardAvailable = useAppSelector(isManualBonusAvailableSelector)
  const freeSpins = useAppSelector(getFreeSpinsSelector)
  const isInviteFriendsAvailable = useIsInviteFriendsAvailable()
  const isInvitationRewardAvailable = useAppSelector(isInvitationBonusAvailableSelector)
  const isSocialMediaRewardAvailable = useAppSelector(isSocialMediaRewardAvailableSelector)
  // open dialog if timer null
  // const timerId = getItemFormLocalStorage('WELCOME_EMAIL_DIALOG_TIMER_ID')
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  const isWeeklyBonusAvailable = useAppSelector(getWeeklyRewardBonusesSelector)

  const isLiveCasinoShown = useAppSelector((state) =>
    getItemFormLocalStorageSelector(state, IS_LIVE_CASINO_SHOWN, false)
  )

  const isSignUpSession = useAppSelector(isSignUpSessionSelector)
  const isOptInNowAvailable = useAppSelector(isOptInNowAvailableSelector)

  const isDailyBonusAvailableWithCommonConditionsAndGCGenerator = useAppSelector(
    isDailyBonusAvailableWithCommonConditionsAndGCGeneratorSelector
  )

  return {
    isQuickStart,
    isPreviewSessionAvailable: false,
    isLegalsUpdatedAvailable,
    // isPlayerSafety: !wasOpenedPlayerSafety,
    isDailyBonusAvailable: isDailyBonusAvailableWithCommonConditionsAndGCGenerator,
    // isJackpotMyStashUnlocked: !!myStashWin.amount,
    isFreeSpinsAvailable: freeSpins?.length > 0 && !process.env.FREESPINS_BAR_ENABLED,
    isRewardAvailable,
    isOffersAvailable: isOffersAvailable && !isForceVerifyKYC,
    isInvitationRewardAvailable,
    // isWelcomeEmailAvailable: !timerId,
    isInviteFriendsAvailable: isInviteFriendsAvailable && !isSignUpSession,
    isWeeklyBonusAvailable: isWeeklyBonusAvailable?.length > 0,
    isOptInNowAvailable: isJackpotEnabled && isOptInNowAvailable && !isSignUpSession,
    isLiveCasinoDialogAvailable: !isLiveCasinoShown && !isSignUpSession,
    isSocialMediaRewardAvailable,
  }
}
